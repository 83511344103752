import * as React from "react"
import Header from "../../components/header"
import Footer from "../../components/footer"
import ButtonLink from "../../components/button-link"
import Hero from "../../components/hero"
import ImageSection from "../../components/image-section"
import Seo from "../../components/seo"

const ServicePage = () => {  
  const imgs = [
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Logo_Google_Analytics.svg/1200px-Logo_Google_Analytics.svg.png",
    "https://markating.nl/wp-content/uploads/2018/08/Google-Search-Console-Logo.jpg",  
    "https://seeklogo.com/images/S/strapi-logo-3551DD3743-seeklogo.com.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1969px-Laravel.svg.png",
  ]

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'SEO optimalisatie' }}/>
      
      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <Hero data={{
        cta: true,
        image: 'https://images.unsplash.com/photo-1594663653925-365bcbf7ef86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80'
      }}>
        <h1 className="text-white col-span-7">Word vindbaar met <span className="text-brand-orange">SEO</span></h1>
        <p className="col-span-5 text-white">
          Staat jouw website bovenaan in Google? 
          Wordt jouw bedrijf als eerste gevonden door klanten?
          Zijn jouw pagina's gebruiksvriendelijk?
          Search engine optimisation is de sleutel naar het succes van jouw website. Wij kunnen het voor je regelen.
        </p>
      </Hero>

      {/* Section */}
      <ImageSection image="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80">
        <h1>Wat SEO is</h1>
        <p>
          Search engine optimisation is het proces waarmee we websites vindbaar maken. Om te zorgen dat een potentiële klant jouw website vindt moet de zoekmachine precies weten wat je aanbiedt. Dat doen we door tags toe te voegen aan jouw site die de zoekmachine begrijpt.
        </p>
        <ButtonLink to="/diensten">Bekijk al onze diensten</ButtonLink>
      </ImageSection>

      {/* What we work with */}
      <section className="container mx-auto py-16">
        <div className="text-center mb-8">
          <h1>Waar we mee werken</h1>
        </div>
        <div className="flex flex-wrap gap-16 justify-center">
          {imgs.map((img: any) => (
            <img src={img} className="h-16"></img>
          ))}
        </div>
      </section>

      {/* Section */}
      <ImageSection reverse image="https://images.unsplash.com/photo-1593438002985-ce805be04da9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80">
        <h1>SEO voor jouw bedrijf</h1>
        <p>
          We testen op snelheid.
        </p>
        <ButtonLink to="/contact">Neem contact op</ButtonLink>
      </ImageSection>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default ServicePage